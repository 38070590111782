<template>
  <div class="nk-wrap" id="nk-wrap">

    <loading
      :active.sync="isLoading"
      :can-cancel="true"
      :is-full-page="fullPage"
    ></loading>

    <!------Header------->
    <header
      class="nk-header page-header is-transparent is-sticky is-dark"
      id="header"
    >
      <!-- Header @s -->

      <div class="header-main">
        <div class="header-container container container-xxl">
          <div class="header-wrap">
            <!-- Logo @s -->

            <div
              class="header-logo logo animated"
              data-animate="fadeInDown"
              data-delay=".6"
            >
              <a href="/" class="logo-link" v-scroll-to="'#home'">
                <img
                  class="logo-dark"
                  src="images/logo-header.png"
                  alt="logo"
                />
                <img
                  class="logo-light"
                  src="images/logo-header.png"
                  alt="logo"
                />
              </a>
            </div>
            <!-- Menu Toogle @s -->

            <div class="header-nav-toggle">
              <a
                href="#"
                class="navbar-toggle"
                data-menu-toggle="header-menu"
                @click="toggleMobNavbar"
              >
                <div class="toggle-line">
                  <span> </span></div
              ></a>
            </div>
            <!-- Menu @s -->

            <div
              id="header-menu-active-class"
              class="header-navbar animated"
              data-animate="fadeInDown"
              data-delay=".6"
            >
              <nav class="header-menu" id="header-menu">
                <ul class="menu">
                  <li class="menu-item">
                    <a
                      class="menu-link nav-link menu-toggle"
                      v-scroll-to="'#home'"
                      >Home</a
                    >
                  </li>
                  <li class="menu-item">
                    <a
                      class="menu-link nav-link menu-toggle"
                      v-scroll-to="'#live-auctions'"
                      >Live Auctions</a
                    >
                  </li>
                  <li class="menu-item">
                    <a
                      class="menu-link nav-link menu-toggle"
                      v-scroll-to="'#luxury-jewelry'"
                      >Luxury Jewelry</a
                    >
                  </li>
                  <li class="menu-item">
                    <a
                      class="menu-link nav-link menu-toggle"
                      v-scroll-to="'#contact-us'"
                      >Contact Us</a
                    >
                  </li>
                  <li class="menu-item" style="border:0px;">
                    <a
                      class="menu-link nav-link"
                      href="https://nozomifund.com/" target="_blank"
                      ><img
                    src="images/nzm-menu-logo-sm.png"
                    alt="header"
                    style="max-width: 80%;    max-width: 70px;"
                  /></a>
                  </li>
                  <li class="menu-item">
                    <button
                      class="menu-btn btn btn-round btn-primary"
                      @click="connectMetamask()"
                    >
                      Connect Metamask
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
            <!-- .header-navbar @e -->
          </div>
        </div>
      </div>
      <!-- .header-main @e -->

      <div class="banner banner-fs tc-light" id="home">
        <div class="nk-block nk-block-sm nk-block-header my-auto">
          <div class="container container-xxl pt-5">
            <div
              class="row align-items-center justify-content-center justify-content-lg-between gutter-vr-30px"
            >
              <div class="col-lg-7 order-lg-last">
               
                    <div
                  class="banner-gfx banner-gfx-auto animated"
                  data-animate="fadeInUp"
                  data-delay=".7"
                >
                  <img
                    src="images/video/apple-by-the-sea.gif"
                    alt="header"
                    style="max-width: 80%"
                  />
                </div>
                  <!-- <div class="nk-block-video">
                    <img src="images/video/banner-video-poster.png" alt="video" /><a
                      href="images/video/nftApplesbytheSea.mp4"
                      class="nk-block-video-play video-popup btn-play btn-play-light"
                      ><em class="fas fa-play"></em
                    ></a>

                  </div> -->


                
              </div>
              <!-- .col -->

              <div class="col-xl-5 col-lg-6 col-sm-10 text-center text-lg-left">
                <div class="banner-caption">
                  <h1
                    class="title title-lg ttu animated"
                    data-animate="fadeInUp"
                    data-delay=".8"
                  >
                    Get your Exclusive Digital Assets - NFT from Nozomi
                  </h1>
                  <h6
                    class="title title-lg ttu lead animated"
                    data-animate="fadeInUp"
                    data-delay=".8"
                    style="font-size: 1rem; color: #97afd5"
                  >
                    Authenticated NFTs. Sell or Buy with Confidence.
                  </h6>
                  <br><br>


                  <!-- <ul
                    class="btn-grp btn-grp-break justify-content-center justify-content-sm-start gutter-vr-20px"
                  >
                    <li
                      class="animated"
                      data-animate="fadeInUp"
                      data-delay="0.8"
                    >
                      <a
                        href="https://www.youtube.com/watch?v=ixz_b6d2l1U"
                        class="menu-btn btn btn-round btn-primary video-popup"
                      >
                        <span>Discover NFTS </span></a
                      >
                    </li>
                  </ul> -->
                </div>
              </div>
              <!-- .col -->
            </div>
            <!-- .row -->
          </div>

          <!-------Paragraph After main banner----------->

          <div class="container">
            <div class="row justify-content-center text-center">
              <div class="col-lg-8">
                <div class="section-head section-head-s2 mt-5 pt-5">
                  <h2
                    class="title title-lg animated"
                    data-animate="fadeInUp"
                    data-delay="0.1"
                    title=""
                  >
                    NFT Gallery - Buy & Sell 'Digital paired Assets'
                  </h2>
                  <h3
                    class="animated title-sm"
                    data-animate="fadeInUp"
                    data-delay="0.2" style="color:#f42f54;"
                  >
                    NFTs are a new rage - for emotional or investment reasons.
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div class="container container-xxl mt-10">
            <div class="nk-block">
              <div
                class="row align-items-center justify-content-center justify-content-xl-between gutter-vr-30px"
              >
                <div class="col-xxl-12 col-xl-12 col-lg-12">
                  <div class="nk-block-text">
                    <div
                      class="animated text-center"
                      data-animate="fadeInUp"
                      data-delay="0.3"
                    >
                      <p>
                        Investments and trading in NFTs spiked 21000% from 2020
                        to top $17 billion in 2021. Here is your chance to own
                        both the NFT and the physical object.
                      </p>

                      <p>
                        Unlike other NFT marketplaces, the Nozomi NFT
                        marketplace is an exclusive invitation-only digital
                        space where we have a select group of clients and
                        talented artists. Moreover, we personally verify,
                        authenticate, create, buy and sell NFTs in a controlled,
                        safe, and secure environment.
                      </p>

                      <p>
                        We pride ourselves in appraising and digitizing original
                        NFT assets for their authenticity, ownership, and value.
                        Here we verify authenticity directly to guarantee
                        assets, artists, and clients so you can choose with
                        peace of mind.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-----End Paragraph after Main Banner---------->

          
        </div>

        <!-- Place Particle Js -->

        <div
          id="particles-bg"
          class="particles-container is-fixed particles-bg op-20"
        ></div>
      </div>
    </header>

    <!-----Header End---------->

    <!--------Main Content start------->
    <main class="nk-pages tc-light">
      <!---Nfts section start---->
      <section class="section section-team" id="live-auctions">
        <div class="container">
          <!-- Section Head @s -->
          <div
            class="section-head section-head-s2 justify-content-center text-center"
          >
            <h2
              class="title title-xl animated fadeInUp"
              data-animate="fadeInUp"
              data-delay="0.1"
              title="Fine Art"
              style="visibility: visible; animation-delay: 0.1s"
            >
              Dive into NFT Live Auctions and Fund Raisers
            </h2>

            <h3
              class="title title-md animated fadeInUp"
              data-animate="fadeInUp"
              data-delay="0.1"
              style="visibility: visible; animation-delay: 0.1s"
            >
              Indulge in owning “authenticated” rare art, jewelry, and
              collectibles!
            </h3>
          </div>

          <div class="nk-block">
            <div
              class="row align-items-center justify-content-center justify-content-xl-between gutter-vr-30px"
            >
              <div class="col-xxl-12 col-xl-12 col-lg-12">
                <div class="nk-block-text">
                  <div
                    class="animated text-center"
                    data-animate="fadeInUp"
                    data-delay="0.3"
                  >
                    <p>
                      We conduct live and transparent auctions and fundraisers.
                    </p>

                    <p>
                      Provenance documentation authenticates art with details on
                      the creator, history, and appraisal value.
                    </p>

                    <p>Chase away any fears of fraud, plagiarism, and theft.</p>

                    <p>
                      OpenSea's marketplace analysis showed that plagiarized art
                      and fake collections were over 80 percent!
                    </p>

                    <p>
                      We verify assets, artists, and clients, guaranteeing
                      authenticity. You are in safe hands!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <!-- Section Head @s -->
          <div
            class="section-head section-head-s2 justify-content-center text-center" style="padding-top:60px;"
          >
            <h2
              class="title title-xl animated fadeInUp"
              data-animate="fadeInUp"
              data-delay="0.1"
              title="Live Auctions"
              style="visibility: visible; animation-delay: 0.1s"
            >
              Live Auctions
            </h2>

            
          </div>


          <!-- .section-head @e --><!-- Block @s -->
          <div class="nk-block nk-block-team-list team-list">
            <div class="row justify-content-center text-center">
              <div class="col-md-4 col-sm-6 col-12">

                <div class="nk-block-video">
                    <img src="images/video/nftApplesbytheSea.jpg" alt="video" /><a
                      href="images/video/nftApplesbytheSea.mp4"
                      class="nk-block-video-play video-popup btn-play btn-play-light"
                      ><em class="fas fa-play"></em
                    ></a>

                  </div>
                  <h5 class="team-name title title-sm">Apples by the Sea</h5>
                  <span class="team-position">Starting from<span class="price-txt">7 Ether</span></span>

              </div>
              <!-- .col -->
              <div class="col-md-4 col-sm-6 col-12">

                <div class="nk-block-video">
                    <img src="images/video/nftSidewaysGlance.jpg" alt="video" /><a
                      href="images/video/nftSidewaysGlance.mp4"
                      class="nk-block-video-play video-popup btn-play btn-play-light"
                      ><em class="fas fa-play"></em
                    ></a>

                  </div>
                  <h5 class="team-name title title-sm">Sideways Glance</h5>
                  <span class="team-position">Starting from<span class="price-txt">5 Ether</span></span>

              </div>
              <!-- .col -->
              <div class="col-md-4 col-sm-6 col-12">

                <div class="nk-block-video">
                    <img src="images/video/nftCoffee.jpg" alt="video" /><a
                      href="images/video/nftCoffee.mp4"
                      class="nk-block-video-play video-popup btn-play btn-play-light"
                      ><em class="fas fa-play"></em
                    ></a>

                  </div>
                  <h5 class="team-name title title-sm">Coffee Tile</h5>
                  <span class="team-position">Starting from<span class="price-txt">3 Ether</span></span>
                
                
              </div>
              <!-- .col -->
              <div class="col-md-3 col-6" style="display:none;">
                <div class="team">
                  <div class="team-photo team-photo-md">
                    <img src="images/nft-images/04.jpg" alt="team" /><a
                      href="#team-popup-4"
                      class="team-show content-popup"
                    ></a>
                  </div>
                  <h5 class="team-name title title-sm">NFT Title</h5>
                </div>
                <!-- Start .team-profile  -->
                <div id="team-popup-4" class="team-popup mfp-hide">
                  <a title="Close" class="mfp-close">×</a>
                  <div class="row align-items-start">
                    <div class="col-md-6">
                      <div class="team-photo">
                        <img src="images/nft-images/04.jpg" alt="team" />
                      </div>
                    </div>
                    <!-- .col  -->
                    <div class="col-md-6">
                      <div class="team-popup-info pl-md-3">
                        <h3 class="team-name title title-lg pt-4">NFT Title</h3>

                        <p>
                          Lorem ipsum dolor sit amet, consec tetur adipis icing
                          elit. Simi lique, autem.
                        </p>
                        <p>
                          Tenetur quos facere magnam volupt ates quas esse
                          Sedrep ell endus mole stiae tates quas esse Sed repell
                          endus molesti aela uda ntium quis quam iusto minima
                          thanks.
                        </p>
                      </div>
                    </div>
                    <!-- .col  -->
                  </div>
                  <!-- .row  -->
                </div>
                <!-- .team-profile  -->
              </div>
            </div>
            <!-- .row -->
          </div>
          <!-- Block @r -->
        </div>
      </section>
      <!---Nfts section End------>

      <!-------------------->
      <!-- <section class="section" id="discover-nfts">
        <div class="container">
          <div class="row justify-content-center text-center">
            <div class="col-lg-8">
              <div class="section-head section-head-s2">
                <h2
                  class="title title-xl animated"
                  data-animate="fadeInUp"
                  data-delay="0.1"
                  title="About NFTs"
                >
                  Marketplace - Discover NFTs
                </h2>
                <p class="animated" data-animate="fadeInUp" data-delay="0.2">
                  Unique NFTs - Digital Collectibles - may be found on this
                  fresh new futuristic NFT platform.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="container container-xxl">
          <div class="nk-block">
            <div
              class="row align-items-center justify-content-center justify-content-xl-between gutter-vr-30px"
            >
              <div class="col-xxl-6 col-xl-6 col-lg-8">
                <div
                  class="nk-block-img nk-block-plx animated"
                  data-animate="fadeInUp"
                  data-delay="0.3"
                >
                  <img
                    class="shadow rounded"
                    src="images/app-screens/sc-medium-b.png"
                    alt=""
                  /><img
                    class="nk-block-img-plx plx-screen shadow rounded"
                    src="images/app-screens/sc-small-d.jpg"
                    alt=""
                  /><img
                    class="nk-block-img-plx plx-circle plx-circle-s1"
                    src="images/gfx/circle-a.png"
                    alt=""
                  /><img
                    class="nk-block-img-plx plx-polygon plx-polygon-s1"
                    src="images/gfx/polygon-a.png"
                    alt=""
                  /><img
                    class="nk-block-img-plx plx-triangle plx-triangle-s1"
                    src="images/gfx/triangle-a.png"
                    alt=""
                  />
                </div>
              </div>
              <div class="col-xxl-5 col-xl-6 col-lg-8">
                <div class="nk-block-text">
                  <div
                    class="feature feature-inline feature-middle animated"
                    data-animate="fadeInUp"
                    data-delay="0.3"
                  >
                    <div class="feature-icon feature-icon-md">
                      <em class="icon icon-md icon-grd ikon ikon-bulb"></em>
                    </div>
                    <div class="feature-text">
                      <p>
                        NFTs include digital items such as visual arts, music,
                        games, and video that are very unique.
                      </p>
                    </div>
                  </div>

                  <div
                    class="feature feature-inline feature-middle animated"
                    data-animate="fadeInUp"
                    data-delay="0.3"
                  >
                    <div class="feature-icon feature-icon-md">
                      <em class="icon icon-md icon-grd ikon ikon-paricle"></em>
                    </div>
                    <div class="feature-text">
                      <p>
                        Sell your NFTs (digital collectibles) and earn in
                        crypto, also buy NFTs with smart contracts.
                      </p>
                    </div>
                  </div>

                  <div
                    class="feature feature-inline feature-middle animated"
                    data-animate="fadeInUp"
                    data-delay="0.3"
                  >
                    <div class="feature-icon feature-icon-md">
                      <em class="icon icon-md icon-grd ikon ikon-bulb-2"></em>
                    </div>
                    <div class="feature-text">
                      <p>
                        NFTs include digital items such as visual arts, music,
                        games, and video that are very unique.
                      </p>
                    </div>
                  </div>

                  <div
                    class="feature feature-inline feature-middle animated"
                    data-animate="fadeInUp"
                    data-delay="0.3"
                  >
                    <div class="feature-icon feature-icon-md">
                      <em
                        class="icon icon-md icon-grd ikon ikon-document-2"
                      ></em>
                    </div>
                    <div class="feature-text">
                      <p>
                        Sell your NFTs (digital collectibles) and earn in
                        crypto, also buy NFTs with smart contracts.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> -->
      <!-- // -->

      <!-------------------->

      <!---Nfts section start---->
      <section class="section section-team" id="luxury-jewelry">
        <div class="container">
          <!-- Section Head @s -->
          <div
            class="section-head section-head-s2 justify-content-center text-center"
          >
            <h2
              class="title title-xl animated fadeInUp"
              data-animate="fadeInUp"
              data-delay="0.1"
              title="Fine Art"
              style="visibility: visible; animation-delay: 0.1s"
            >
              Luxury Jewelry
            </h2>

            <div class="row align-items-center justify-content-center">
              <div class="col-sm-10">
                <div class="nk-block-text">
                  <div
                    data-animate="fadeInUp"
                    data-delay="0.3"
                    class="animated text-center fadeInUp"
                    style="visibility: visible; animation-delay: 0.3s"
                  >
                    <p>
                      Luxury jewelry shines best with its digital-physical pair.
                      Besides the exquisite jewelry, buyers get its digital NFT
                      too that traces ownership history and guarantees
                      authenticity.
                    </p>
                    <p>
                      Ownership details get transparently and securely stored on
                      the blockchain. You can trade the diamonds using the NFT
                      by retaining the same diamond price levels, if not higher.
                    </p>
                    <p>
                      Rare 1.5 Carat yellowish diamond in a platinum setting.
                      Ring is vaulted in Pittsburgh, PA. Diamond District.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- .section-head @e --><!-- Block @s -->
          <div class="nk-block nk-block-team-list team-list">
            <div class="row justify-content-center">
              <div class="col-md-4 col-6">
                <div class="team">
                  <div class="team-photo team-photo-md">
                    <img src="images/nft-images/ring-01.jpg" alt="Classic Vintage Setting" /><a
                      href="#team-popup-j1"
                      class="team-show content-popup"
                    ></a>
                  </div>
                  <h5 class="team-name title title-sm">Classic Vintage Setting</h5>
                  <span class="team-position">Starting from<span class="price-txt">5 Ether</span></span>

                </div>
                <!-- Start .team-profile  -->
                <div id="team-popup-j1" class="team-popup mfp-hide">
                  <a title="Close" class="mfp-close">×</a>
                  <div class="row align-items-start">
                    <div class="col-md-6">
                      <div class="team-photo">
                        <img src="images/nft-images/ring-01.jpg" alt="Classic Vintage Setting" />
                      </div>
                    </div>
                    <!-- .col  -->
                    <div class="col-md-6">
                      <div class="team-popup-info pl-md-3">
                        <h3 class="team-name title title-lg pt-4">Classic Vintage Setting</h3>
                  <span class="team-position">Starting from<span class="price-txt">5 Ether</span></span>
                  
                      <h3 class="title title-sm mb-2 mt-2">Description:</h3>
                      <p>
                          Rare 1.5 Carat yellowish diamond in a platinum setting. Ring is vaulted in Pittsburgh, PA. Diamond District.
                        </p>
                        <h3 class="title title-sm mb-2 mt-2">Ring Specifications:</h3>
                        <table>
                          <tr>
                            <td>Carat: </td>
                            <td>2.01</td>
                          </tr>
                          <tr>
                            <td>Cut: </td>
                            <td>Very Good</td>
                          </tr>
                          <tr>
                            <td>Color: </td>
                            <td>I</td>
                          </tr>
                          <tr>
                            <td>Clarity: </td>
                            <td>SI1</td>
                          </tr>
                        </table>

                      </div>
                    </div>
                    <!-- .col  -->
                  </div>
                  <!-- .row  -->
                </div>
                <!-- .team-profile  -->
              </div>
              <!-- .col -->
              <div class="col-md-4 col-6">
                <div class="team">
                  <div class="team-photo team-photo-md">
                    <img src="images/nft-images/ring-02.jpg" alt="Platinum Halo Band" /><a
                      href="#team-popup-j2"
                      class="team-show content-popup"
                    ></a>
                  </div>
                  <h5 class="team-name title title-sm">Platinum Halo Band</h5>
                  <span class="team-position">Starting from<span class="price-txt">5 Ether</span></span>

                </div>
                <!-- Start .team-popup  -->
                <div id="team-popup-j2" class="team-popup mfp-hide">
                  <a title="Close" class="mfp-close">×</a>
                  <div class="row align-items-start">
                    <div class="col-md-6">
                      <div class="team-photo">
                        <img src="images/nft-images/ring-02.jpg" alt="Platinum Halo Band" />
                      </div>
                    </div>
                    <!-- .col  -->
                    <div class="col-md-6">
                      <div class="team-popup-info pl-md-3">
                        <h3 class="team-name title title-lg pt-4">Platinum Halo Band</h3>
                  <span class="team-position">Starting from<span class="price-txt">5 Ether</span></span>

                        <h3 class="title title-sm mb-2 mt-2">Description:</h3>
                      <p>
                          Rare 1.5 Carat yellowish diamond in a platinum setting. Ring is vaulted in Pittsburgh, PA. Diamond District.
                        </p>
                        <h3 class="title title-sm mb-2 mt-2">Ring Specifications:</h3>
                        <table>
                          <tr>
                            <td>Carat: </td>
                            <td>2.01</td>
                          </tr>
                          <tr>
                            <td>Cut: </td>
                            <td>Very Good</td>
                          </tr>
                          <tr>
                            <td>Color: </td>
                            <td>I</td>
                          </tr>
                          <tr>
                            <td>Clarity: </td>
                            <td>SI1</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <!-- .col  -->
                  </div>
                  <!-- .row  -->
                </div>
                <!-- .team-popup  -->
              </div>
              <!-- .col -->
              <div class="col-md-4 col-6">
                <div class="team">
                  <div class="team-photo team-photo-md">
                    <img
                      src="images/nft-images/ring-03.jpg"
                      alt="1.5-Carat Cushion Cut"
                    /><a
                      href="#team-popup-j3"
                      class="team-show content-popup"
                    ></a>
                  </div>
                 <h5 class="team-name title title-sm">1.5-Carat Cushion Cut</h5>
                  <span class="team-position">Starting from<span class="price-txt">5 Ether</span></span>
                </div>
                <!-- Start .team-profile  -->
                <div id="team-popup-j3" class="team-popup mfp-hide">
                  <a title="Close" class="mfp-close">×</a>
                  <div class="row align-items-start">
                    <div class="col-md-6">
                      <div class="team-photo">
                        <img
                          src="images/nft-images/ring-03.jpg"
                          alt="1.5-Carat Cushion Cut"
                        />
                      </div>
                    </div>
                    <!-- .col  -->
                    <div class="col-md-6">
                      <div class="team-popup-info pl-md-3">
                        <h3 class="team-name title title-lg pt-4">1.5-Carat Cushion Cut</h3>
                  <span class="team-position">Starting from<span class="price-txt">5 Ether</span></span>

                        <h3 class="title title-sm mb-2 mt-2">Description:</h3>
                      <p>
                          Rare 1.5 Carat yellowish diamond in a platinum setting. Ring is vaulted in Pittsburgh, PA. Diamond District.
                        </p>
                        
                      </div>
                    </div>
                    <!-- .col  -->
                  </div>
                  <!-- .row  -->
                </div>
                <!-- .team-profile  -->
              </div>
              <!-- .col -->
              
            </div>
            <!-- .row -->

            <div class="row text-center align-items-lg-start gutter-vr-40px">
              <div class="col-lg-12 col-sm-12">
                <a  href="#"  v-scroll-to="'#contact-us'"  class="menu-btn btn btn-round btn-primary"
                  ><span>Sign Up Now</span></a
                >
              </div>
            </div>
          </div>
          <!-- Block @r -->
        </div>
      </section>
      <!---Nfts section End------>

      <!-------------------->
      <section class="section" id="listed-and-sell">
        <div class="container">
          <div class="row justify-content-center text-center">
            <div class="col-lg-10">
              <div class="section-head section-head-s2">
                <h2
                  class="title title-xl animated"
                  data-animate="fadeInUp"
                  data-delay="0.1"
                  title="Sell or Buy"
                >
                  Authenticate your NFTs - Sell or Buy
                </h2>
                <!-- <p class="animated" data-animate="fadeInUp" data-delay="0.2">
                  You are simply one click away from listing your NFT on our
                  site and selling them for a lot of money.
                </p> -->
              </div>
            </div>
          </div>
        </div>
        <div class="container container-xxl">
          <div class="nk-block">
            <div
              class="row align-items-center justify-content-center justify-content-xl-between gutter-vr-30px"
            >
              <div class="col-xxl-5 col-xl-6 col-lg-8">
                <div class="nk-block-text">
                  <div
                    class="feature feature-inline feature-middle animated"
                    data-animate="fadeInUp"
                    data-delay="0.3"
                  >
                    <p>
                      Our authentic digital paired assets have an accompanying
                      video and encoding powered by the Ethereum blockchain.
                    </p>
                  </div>

                  <div
                    class="feature feature-inline feature-middle animated"
                    data-animate="fadeInUp"
                    data-delay="0.3"
                  >
                    <p>
                      You can digitize fine art, jewelry, or unique
                      collectibles. We authenticate and create NFTs on the
                      Nozomi platform. You can have smart contracts and tokens
                      to mint your NFTs.
                    </p>
                  </div>

                  <div
                    class="feature feature-inline feature-middle animated"
                    data-animate="fadeInUp"
                    data-delay="0.3"
                  >
                    <a href="#"  v-scroll-to="'#contact-us'" class="menu-btn btn btn-round btn-primary"
                      ><span>Sign Up Now</span></a
                    >
                  </div>
                </div>
              </div>

              <div class="col-xxl-6 col-xl-6 col-lg-8">
                <div
                  class="banner-gfx banner-gfx-auto animated"
                  data-animate="fadeInUp"
                  data-delay=".7"
                >
                  <img
                    src="images/azalea/earn-listing-img.png"
                    alt="header"
                    style="max-width: 100%"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- // -->
<!-----------Faqs Start -------------->

        <section class="section" id="faqs">

          <div class="container">
            <div class="row justify-content-center text-center">
              <div class="col-lg-8">
                <div class="section-head section-head-s2 mt-5 pt-5">
                  

                  <h2
                  class="title title-xl animated"
                  data-animate="fadeInUp"
                  data-delay="0.1"
                  title="FAQs"
                >
                  FAQs
                </h2>
                <p class="animated" data-animate="fadeInUp" data-delay="0.2">
                  If you have any other questions, please get in touch.
                </p>

                </div>
              </div>
            </div>

            <div class="row justify-content-center">
              <div class="col-sm-10">
                <div class="tab-pane fade show active" id="tab-2-1">
                  <div class="accordion" id="acc-1">
                    <div
                      class="accordion-item accordion-item-s3 animated"
                      data-animate="fadeInUp"
                      data-delay="0.4"
                    >
                      <h5
                        class="accordion-title accordion-title-sm"
                        data-toggle="collapse"
                        data-target="#acc-1-1"
                      >
                        What is digital-physical pairing?
                        <span class="accordion-icon"></span>
                      </h5>

                      <div
                        id="acc-1-1"
                        class="collapse show"
                        data-parent="#acc-1"
                      >
                        <div class="accordion-content">
                          <p>
                            Digital Physical pairing means the buyer acquires
                            both the object and the digital NFT representation
                            of the object.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div
                      class="accordion-item accordion-item-s3 animated"
                      data-animate="fadeInUp"
                      data-delay="0.5"
                    >
                      <h5
                        class="accordion-title accordion-title-sm collapsed"
                        data-toggle="collapse"
                        data-target="#acc-1-2"
                      >
                        What is Real Asset Ownership?
                        <span class="accordion-icon"> </span>
                      </h5>

                      <div id="acc-1-2" class="collapse" data-parent="#acc-1">
                        <div class="accordion-content">
                          <p>
                            *Real Asset Ownership (possession) includes
                            ownership, copyright, verified authentication, and
                            asset exclusivity.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div
                      class="accordion-item accordion-item-s3 animated"
                      data-animate="fadeInUp"
                      data-delay="0.6"
                    >
                      <h5
                        class="accordion-title accordion-title-sm collapsed"
                        data-toggle="collapse"
                        data-target="#acc-1-3"
                      >
                        Do you provide digital collateral videos of the asset?
                        <span class="accordion-icon"> </span>
                      </h5>

                      <div id="acc-1-3" class="collapse" data-parent="#acc-1">
                        <div class="accordion-content">
                          <p>Digital Video is made exclusively for assets.</p>
                        </div>
                      </div>
                    </div>

                    <div
                      class="accordion-item accordion-item-s3 animated"
                      data-animate="fadeInUp"
                      data-delay="0.6"
                    >
                      <h5
                        class="accordion-title accordion-title-sm collapsed"
                        data-toggle="collapse"
                        data-target="#acc-1-4"
                      >
                        Do you provide a service for the tokenization of assets?
                        <span class="accordion-icon"> </span>
                      </h5>

                      <div id="acc-1-4" class="collapse" data-parent="#acc-1">
                        <div class="accordion-content">
                          <p>
                            Tokenization of Asset. Have your NFT minted on the
                            Ethereum Blockchain. or Mint your NFT on a Platform
                            of your choice.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div
                      class="accordion-item accordion-item-s3 animated"
                      data-animate="fadeInUp"
                      data-delay="0.6"
                    >
                      <h5
                        class="accordion-title accordion-title-sm collapsed"
                        data-toggle="collapse"
                        data-target="#acc-1-5"
                      >
                        Can we sell NFTs in fundraiser auctions?
                        <span class="accordion-icon"> </span>
                      </h5>

                      <div id="acc-1-5" class="collapse" data-parent="#acc-1">
                        <div class="accordion-content">
                          <p>
                            Yes, you can sell your NFT on our Nozomi live
                            auction fundraiser.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </section>
          

          <!-----------End Faqs------------------>
      <!-------------------->
      <!-- <section class="section" id="nft-guides">
        <div class="container">
          <div class="row justify-content-center text-center">
            <div class="col-lg-8">
              <div class="section-head section-head-s2">
                <h2
                  class="title title-xl animated"
                  data-animate="fadeInUp"
                  data-delay="0.1"
                  title="Guides"
                >
                  Guides For NFTLand
                </h2>
                <p>
                  Get to know how to use NFTLand - Marketplace, how to sell or
                  buy, and how to create your store on our platform.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="container container-xxl">
          <div class="nk-block">
            <div
              class="row align-items-center justify-content-center justify-content-xl-between gutter-vr-30px"
            >
              <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6">
                <div
                  class="banner-gfx banner-gfx-auto animated"
                  data-animate="fadeInUp"
                  data-delay=".7"
                >
                  <img
                    src="images/azalea/guide01.png"
                    alt="header"
                    style="max-width: 70%"
                  />
                  <h5 class="title title-sm ttu">What Are NFTs?</h5>
                  <p>
                    NFTs (non-fungible tokens) that exist on the blockchain as
                    cryptocurrencies, and each NFTs has its own token value.
                  </p>
                </div>
              </div>

              <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6">
                <div
                  class="banner-gfx banner-gfx-auto animated"
                  data-animate="fadeInUp"
                  data-delay=".7"
                >
                  <img
                    src="images/azalea/guide02.png"
                    alt="header"
                    style="max-width: 70%"
                  />

                  <h5 class="title title-sm ttu">Method To List Items?</h5>
                  <p>
                    NFTs (non-fungible tokens) that exist on the blockchain as
                    cryptocurrencies, and each NFTs has its own token value.
                  </p>
                </div>
              </div>

              <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6">
                <div
                  class="banner-gfx banner-gfx-auto animated"
                  data-animate="fadeInUp"
                  data-delay=".7"
                >
                  <img
                    src="images/azalea/guide03.png"
                    alt="header"
                    style="max-width: 70%"
                  />

                  <h5 class="title title-sm ttu">Method Of Selling?</h5>
                  <p>
                    NFTs (non-fungible tokens) that exist on the blockchain as
                    cryptocurrencies, and each NFTs has its own token value.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> -->
      <!-- // -->

      <!-------Contact Section--------->
      <section class="section" id="contact-us">
        <div class="container">
          <!-- Block @s -->

          <div class="nk-block nk-block-about">
            <div
              class="row justify-content-between align-items-center gutter-vr-50px"
            >
              <div class="col-lg-6">
                <div class="nk-block-text">
                  <div class="nk-block-text-head">
                    <h2
                      class="title title-lg ttu animated"
                      data-animate="fadeInUp"
                      data-delay="0.7"
                    >
                      Contact Us
                    </h2>

                    <p
                      class="animated"
                      data-animate="fadeInUp"
                      data-delay="0.8"
                    >Sign up for the Nozomi NFT marketplace.
                    </p>
                  </div>

                  <div >
                    <div class="alert alert-success mb-2" role="alert" v-if="emailSuccess">{{ emailSuccess }} <br></div>
                    <div class="alert alert-danger mb-2" role="alert" v-if="emailError">{{ emailError }} <br></div>
                    
                  </div>

                  <form class="nk-form-submit">
                    <div class="row">
                      <div class="col-sm-6">
                        <div
                          class="field-item animated"
                          data-animate="fadeInUp"
                          data-delay="0.8"
                        >
                          <label class="field-label ttu">Your Name </label>

                          <div class="field-wrap">
                            <input
                              name="contact-name"
                              placeholder="Introduce yourself"
                              type="text" v-model="name"
                              class="input-bordered required"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="col-sm-6">
                        <div
                          class="field-item animated"
                          data-animate="fadeInUp"
                          data-delay="0.9"
                        >
                          <label class="field-label ttu">Your Email </label>

                          <div class="field-wrap">
                            <input
                              name="contact-email"
                              placeholder="Who do we replay to"
                              type="email" v-model="email"
                              class="input-bordered required email"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="field-item animated"
                      data-animate="fadeInUp"
                      data-delay="1.0"
                    >
                      <label class="field-label ttu">Your Message </label>

                      <div class="field-wrap">
                        <textarea
                          name="contact-message" v-model="message"
                          placeholder="Leave your question or comment here"
                          class="input-bordered input-textarea required"
                        >
                        </textarea>
                      </div>
                    </div>
                    <input
                      type="text"
                      class="d-none"
                      name="form-anti-honeypot"
                      value=""
                    />

                    <div class="row">
                      <div
                        class="col-sm-5 text-right animated"
                        data-animate="fadeInUp"
                        data-delay="1.1"
                      >
                        <button type="button" @click="sendEmail" class="btn btn-round btn-primary">
                          SEND
                        </button>
                      </div>

                      <div class="col-sm-7 order-sm-first">
                        <div class="form-results"></div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div class="col-lg-5 text-center order-lg-first">
                <div
                  class="nk-block-contact nk-block-contact-s1 animated"
                  data-animate="fadeInUp"
                  data-delay="0.1"
                >
                  <ul class="contact-list">
                    <li
                      class="animated"
                      data-animate="fadeInUp"
                      data-delay="0.2"
                    >
                      <em class="contact-icon fas fa-phone"></em>

                      <div class="contact-text">
                        <a href="tel:9196560676"><span>(919) 656-0676</span></a>
                      </div>
                    </li>
                    <li
                      class="animated"
                      data-animate="fadeInUp"
                      data-delay="0.3"
                    >
                      <em class="contact-icon fas fa-envelope"></em>

                      <div class="contact-text">
                        <a href="mailto:info@nozomifund.com"
                          ><span>info@nozomifund.com</span></a
                        >
                      </div>
                    </li>
                    <!-- <li
                      class="animated"
                      data-animate="fadeInUp"
                      data-delay="0.4"
                    >
                      <em class="contact-icon fas fa-paper-plane"></em>

                      <div class="contact-text">
                        <span>Join us on Telegram </span>
                      </div>
                    </li> -->
                  </ul>

                  <div
                    class="nk-circle-animation nk-df-center white small"
                  ></div>
                  <!-- .circle-animation -->
                </div>
              </div>
            </div>
          </div>
          <!-- .block @e -->
        </div>
      </section>
      <!----Contact Section End------>
    </main>
    <!--------Main COntent End---------->

    <!----------Footer Start-------->
    <footer class="nk-footer-bar section section-s tc-light">
      <div class="container container-xxl">
        <div class="row gutter-vr-10px">
          <!-- <div class="col-lg-6 order-lg-last text-lg-right">
            <ul class="footer-nav">
              <li><a href="#">Privacy Policy</a></li>
              <li><a href="#">Terms of sales</a></li>
            </ul>
          </div> -->

          <div class="col-lg-12">
            <div class="copyright-text copyright-text-s2 text-center">
              Copyright © 2022 ALL rights reserved and owned by ai Software.
            </div>
          </div>
        </div>
      </div>
    </footer>

    <div class="nk-ovm nk-ovm-repeat nk-ovm-fixed shape-i">
      <div class="ovm-line"></div>
    </div>

    <!----Preloader ------->
    <div class="preloader preloader-alt no-split">

      <span class="spinner spinner-alt"><img class="spinner-brand" src="images/logo-s2-white.png" alt="">
      </span>
</div>

  </div>
</template>

<script>

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import api from "../config/api";


let ethereum = window.ethereum;

export default {
  components: {
            Loading
        },
  data() {
    return {
      name:"",
      email:"",
      message:"",
      emailSuccess: "",
      emailError: "",
      isActiveMobileMenu: false,
      isLoading: false,
    };
  },
  
  mounted() {},
  methods: {
    
    async connectMetamask() {
      if (typeof ethereum !== "undefined") {
        try {
          const accounts = await ethereum.request({
            method: "eth_requestAccounts",
          });
          const account = accounts[0];
          alert("Your address is: " + account);
        } catch (error) {
          console.log(error);
        }
      } else {
        alert("Please install metamask");
      }
    },
    goto(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;

      window.scrollTo(0, top);
    },
    toggleMobNavbar() {
      document.body.classList.toggle("menu-shown");
    },
    sendEmail(){

      this.isLoading = true;

      api.post('/contact-us', {
        name:this.name,
        email:this.email,
        full_message:this.message,
        nft: true,
      }).then(res => {
        this.name = "";
        this.email = "";
        this.message = "";
        this.isLoading = false;

        this.emailSuccess = res.data.message;
        console.log(res.data.message);


      }).catch(err => {
         console.log(err);
         this.isLoading = false;
         this.emailError = err.data.message;
      })


    }
  },
};
</script>

<style>

.vld-overlay.is-active.is-full-page {
position: fixed !important;
z-index: 9999 !important;
}

.vld-overlay .vld-background {
  background: #ffffff;
  opacity: 0.2;
}

a.link2 {
  color: #ea5152 !important;
}

a.link2:hover {
  color: #97afd5 !important;
}

.logo img {
  height: 60px;
}

@media (min-width: 992px) {
  .logo img {
    height: 75px;
  }
  .header-main {
    padding: 0px 0;
  }
}

body.menu-shown .header-navbar {
  left: 0 !important;
  z-index: 10;
}
.price-txt {
    font-size: 16px;
    color: #ea5152;
    font-weight:600;
    display:block;
}
</style>
