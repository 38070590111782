import Vue from "vue";
import VueRouter from "vue-router";

import Home from "../pages/home";
import testpage from "../pages/test";





Vue.use(VueRouter);
const routes = [
    // {
    //     path: "/",
    //     redirect: "/home"
    // },
    {
        path: "/",
        component: Home
    },
    {
        path: "/test",
        component:testpage
    },

    
];

const router = new VueRouter({
    routes,
    mode:'history'
});

export default router;
